@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Thin-Italic.otf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Light-Italic.otf")
    format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Regular-Italic.otf")
    format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Semibold-Italic.otf")
    format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Bold-Italic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Extrabold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Extrabold-Italic.otf")
    format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "ProximaNova";
  src: url("/fonts/ProximaNova/Proxima-Nova-Black-Italic.otf")
    format("opentype");
  font-weight: 900;
  font-style: italic;
}
