@font-face {
  font-family: "Helvetica";
  src: url("/fonts/Helvetica/HelveticaLTStd-light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("/fonts/Helvetica/HelveticaLTStd-normal.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("/fonts/Helvetica/HelveticaLTStd-bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("/fonts/Helvetica/HelveticaLTStd-black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
