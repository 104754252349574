@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Extra-Light.otf")
    format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Extra-Light-Italic.otf")
    format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Light.otf")
    format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Light-Italic.otf")
    format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Semi-Bold.otf")
    format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Semi-Bold-Italic.otf")
    format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Bold-Italic.otf")
    format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Strong.otf")
    format("opentype");
  font-weight: 750;
  font-style: normal;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Strong-Italic.otf")
    format("opentype");
  font-weight: 750;
  font-style: italic;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Extra-Bold.otf")
    format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "TestSöhneSchmal";
  src: url("/fonts/TestSöhneSchmal/TestSöhneSchmal-Extra-Bold-Italic.otf")
    format("opentype");
  font-weight: 800;
  font-style: italic;
}
